export default ($wire, { modalEventName, hideOverflow = true, isReportForm = false }) => ({
    isOpen: false,
    selectedType: null,
    shouldDisableFields: true,
    isReportForm,

    init() {
        window.addEventListener(modalEventName, event => {
            event.detail === 'open' ? this.open() : this.close();
            this.toggleSourcePage(event);
        });

        this.$watch('$wire.type', value => {
            this.selectedType = value;
            this.updateFieldsState();
        });
    },

    updateFieldsState() {
        // Only run this logic for report form
        if (!this.isReportForm) return;

        const hasUserInfo = Boolean($wire.name) && Boolean($wire.email);
        const hasErrors = $wire.errors?.name || $wire.errors?.email;

        if (hasErrors) {
            this.shouldDisableFields = false;
            return;
        }

        // Store current values when switching to illegal_content
        if (this.selectedType === 'illegal_content' && hasUserInfo) {
            // Store the current user data before clearing
            this.storedName = $wire.name;
            this.storedEmail = $wire.email;

            // Clear name and email fields for illegal_content
            $wire.set('name', '');
            $wire.set('email', '');
        }

        // Restore stored values when switching back from illegal_content
        if (this.selectedType !== 'illegal_content' && this.storedName && this.storedEmail) {
            $wire.set('name', this.storedName);
            $wire.set('email', this.storedEmail);
        }

        this.shouldDisableFields = hasUserInfo && this.selectedType !== 'illegal_content';
    },

    isIllegalContentType() {
        // Only apply this logic for report form
        if (!this.isReportForm) return false;
        return this.shouldDisableFields;
    },

    open() {
        this.isOpen = true;

        if (hideOverflow) {
            document.body.style.overflow = 'hidden';
        }
    },

    close() {
        this.isOpen = false;
        document.body.style.overflow = 'auto';
    },

    toggleSourcePage(data) {
        let sourcePage = 'listing_detail';

        if (data.detail.isOpen) {
            data.detail.isOpen === 'open' ? this.open() : this.close();
            sourcePage = data.detail.sourcePage;
            $wire.dispatch('setUrl', {
                validationUrl: window.location.host,
                protocol: window.location.protocol + '//',
            });
        }

        $wire.dispatch('changeSourcePage', [sourcePage]);
    },
});
